import React, {Component} from 'react';
import {Row, Button} from 'react-materialize';
import {Redirect} from 'react-router-dom';
import SourceRow from './SourceRow';
import sourcesList from './sourcesList';


class Sources extends Component {

    state = {
        sources: {
        }
    }

    submitSources = (e) => {
        e.preventDefault();
        const form = Array.from(e.target.elements)
        const sources = form.filter(source => source.checked)
        if (sources.length === 0){
            alert("Please select at least one source.");
        } else {
            this.setState({
                sources,
                redirect: true
            })
        }
    }

    render() {
        const sources = this.state.sources;
        if(this.state.redirect) return <Redirect
        to={{
          pathname: "/stories",
          state: { sources }
        }}
      />
        return (
            <div className="sources">
                <h6>Load stories from</h6>
                <form onSubmit={this.submitSources}>
                    <div className="source-list">
                    <Row>
                    {Array.from(sourcesList).map(source => {
                            return <SourceRow key={source.id}
                            source={source}/>
                            })
                        }
                    </Row>
                    </div>
                    <Button waves='light' type="submit" className="deep-orange lighten-1">Load Stories</Button>  
                </form>
            </div>
        )
    }
}

export default Sources;