const sources = [
    {
        id: "1",
        name: "HackerNews",
        func: "fetchHackerNews",
        tag: ""
    },{
        id: "2",
        name: "TechCrunch",
        func: "fetchTechCrunch",
        tag: ""
    },{
        id: "3",
        name: "FreeCodeCamp",
        func: "fetchFreeCodeCamp",
        tag: ""
    },{
        id: "4",
        name: "Hacker Noon",
        func: "fetchHackerNoon",
        tag: ""
    },{
        id: "5",
        name: "Codeburst",
        func: "fetchCodeburst",
        tag: ""
    },{
        id: "6",
        name: "Dev.to - Javascript",
        func: "fetchDevTo",
        tag: "javascript"
    },{
        id: "7",
        name: "Dev.to - Web Dev",
        func: "fetchDevTo",
        tag: "webdev"
    },{
        id: "8",
        name: "Dev.to - Discuss",
        func: "fetchDevTo",
        tag: "discuss"
    },{
        id: "9",
        name: "Dev.to - Beginners",
        func: "fetchDevTo",
        tag: "beginners"
    },{
        id: "10",
        name: "Dev.to - React",
        func: "fetchDevTo",
        tag: "react"
    },{
        id: "11",
        name: "Dev.to - Programming",
        func: "fetchDevTo",
        tag: "programming"
    },{
        id: "12",
        name: "Dev.to - Career",
        func: "fetchDevTo",
        tag: "career"
    },{
        id: "13",
        name: "r/Programming",
        func: "fetchReddit",
        tag: "programming"
    },{
        id: "14",
        name: "r/Technology",
        func: "fetchReddit",
        tag: "technology"
    },{
        id: "15",
        name: "r/ProgrammingHumor",
        func: "fetchReddit",
        tag: "programminghumor"
    },{
        id: "16",
        name: "r/Javascript",
        func: "fetchReddit",
        tag: "javascript"
    },{
        id: "17",
        name: "The Hacker News",
        func: "fetchTheHackerNews",
        tag: ""
    }
]

export default sources;