import React, {Component} from 'react';
import { Preloader } from 'react-materialize';
import axios from 'axios';
import NewsList from './NewsList';
// import {fetchCodeburst, fetchTheHackerNews, fetchDevTo, fetchFreeCodeCamp, fetchHackerNews, fetchHackerNoon, fetchReddit, fetchTechCrunch} from './fetches';
class Main extends Component {
  state = {
      allStories: []
  }
  
  parseStory(story){
    const { id, title, url } = story
    return {
      id,
      title,
      url
    }
  }

  capitalize(string){
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  fetchHackerNews = async (tag) => {
    function fetchStoryIDs(){
      return axios.get('https://hacker-news.firebaseio.com/v0/topstories.json')
    }
    
    function fetchStory(storyID){
      return axios.get(`https://hacker-news.firebaseio.com/v0/item/${storyID}.json`)
    }

    

    const top500IDs = await fetchStoryIDs();
      const top25IDs = top500IDs.data.slice(0,25)
      Promise.all(top25IDs.map((story, index) => {
        return fetchStory(story)
        .then(storyDetails => {
          return this.parseStory(storyDetails.data)
        })
      }))
      .then(stories => {
        const allStories = this.state.allStories
        stories.map(story => {
            story.source = "Hacker News"
          allStories.push(story)
        })
        this.setState({
          allStories
        })
      })
      .catch(err => console.error(err))
    }

    fetchTheHackerNews = async (tag) => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Ffeeds.feedburner.com%2FTheHackersNews')
          .then(results => {
            const stories = results.data.items
            const allStories = this.state.allStories
            stories.map(story => {
              story.source = "The Hacker News"
              allStories.push(story)
            })
            this.setState({
              allStories
          })
        })
        .catch(err => console.error(err))
      }


    fetchTechCrunch = async (tag) => {
      axios.get('https://api.rss2json.com/v1/api.json?rss_url=http://feeds.feedburner.com/TechCrunch/')
      .then(results => {
        const stories = results.data.items
        const allStories = this.state.allStories
        stories.map(story => {
          story.source = "TechCrunch"
          allStories.push(story)
        })
        this.setState({
          allStories
      })
    })
      .catch(err => console.error(err))
  }

    fetchReddit = async (tag) => {
      axios.get(`https://www.reddit.com/r/${tag}/hot.json?sort=new`)
      .then(posts => {
        const allStories = this.state.allStories
        const allPosts = posts.data.data.children
        allPosts.map(post => {
            post.data.source = `r/${this.capitalize(tag)}`
            allStories.push(post.data)
        })
        this.setState({
            allStories
        })
      })
      .catch(err => console.error(err))
    }

    fetchFreeCodeCamp = async (tag) => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://www.freecodecamp.org/news/rss/')
        .then(results => {
          const stories = results.data.items
          const allStories = this.state.allStories
          stories.map(story => {
              story.source = "FreeCodeCamp.org"
              allStories.push(story)
          })
          this.setState({
            allStories
        })
      })
        .catch(err => console.error(err))
    }

    fetchHackerNoon = async (tag) => {
      axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://hackernoon.com/feed?truncated=true')
      .then(results => {
        const stories = results.data.items
        const allStories = this.state.allStories
        stories.map(story => {
          story.source = "HackerNoon"
          allStories.push(story)
        })
        this.setState({
          allStories
      })
    })
      .catch(err => console.error(err))
  }

  fetchCodeburst = async (tag) => {
      axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://codeburst.io/feed?truncated=true')
      .then(results => {
        const stories = results.data.items
        const allStories = this.state.allStories
        stories.map(story => {
          story.source = "codeburst.io"
          allStories.push(story)
          return story
        })
        this.setState({
          allStories
      })
    })
      .catch(err => console.error(err))
  }

  fetchDevTo = async (tag) => {
    axios.get(`https://dev.to/api/articles?tag=${tag}`)
    .then(results => {
      const stories = results.data
      const allStories = this.state.allStories
      stories.map(story => {
        const formattedStory = this.parseStory(story)
        formattedStory.source = `Dev.to - ${tag}`
        allStories.push(formattedStory)
        this.setState({
          allStories
        })
        return formattedStory
      })
    })
    .catch(err => console.error(err))
  }
  
  
  componentDidMount(){
      const sources = this.props.location.state.sources   
      
      sources.map(source => {
        const func = source.attributes[1].value
        const tag = source.attributes[2].value
        this[func](tag)
        // console.log(func, tag)
        // let stories = this[func].bind(this, tag)
        // console.log(stories)
        return source
      })
  }

  render() {
      const allStories = this.state.allStories;
      if(allStories.length === 0){
      return <div id="preload-text">
          <h3>Fetching stories...</h3>
          <Preloader size='big'/>
          </div>
      }
  
      return (
          <div className="main">
              <NewsList stories={allStories}/>
          </div>
      )
  }
}

export default Main;